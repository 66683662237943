<template>
  <div>
    <div class="succeed">
      <div class="box">
        <div class="whiff">
          支付成功
        </div>
        <div class="item_box">
          <span>姓名</span> <span>{{ query.XM }}</span>
        </div>
        <div class="item_box">
          <span>卡号</span> <span>{{ query.CARDNO }}</span>
        </div>
        <div class="item_box">
          <span>医生</span> <span>{{ query.YSMC }}</span>
        </div>
        <div class="item_box">
          <span>支付金额</span> <span>{{ query.YJJJE }}</span>
        </div>
        <div class="item_box" v-if="query.FZDD">
          <span>分诊地点</span> <span style="color: red">{{ query.FZDD }}</span>
        </div>
        <div class="item_box" v-else-if="query.TSXX">
          <span>提示信息</span> <span style="color: red">{{ query.TSXX }}</span>
        </div>
        <div class="item_box" v-else>
          <span>取药窗口</span> <span style="color: red">{{ query.INFO }}</span>
        </div>
      </div>
      <p>将在<span>30</span>秒后自动返回首页，请注意保留分诊、取药信息</p>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'
export default {
  data() {
    return {
      query: {},
      count: '',
      timer: '',
      invoiceUrl: '',
      isModalVisible: false
    }
  },
  created() {
    this.query = this.$route.query
    setTimeout(() => {
      this.$router.push('/home')
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    home() {
      this.$router.push('/home')
    },
    showModal() {
      this.isModalVisible = true
    },
    hideModal() {
      this.isModalVisible = false
    },
    async getInvoice() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await jsonPost('/outpatient/viewInvoice', {
        agtOrdNum: this.query.outTradeNo,
        patCardNo: this.query.patCardNo,
        invoiceNum: this.query.receiptNum
        // agtOrdNum: "2301061605229155",
        // patCardNo: "100765865",
        // invoiceNum: "WX202301060351",
      })
      if (res.code === 0) {
        loading.close()
        this.invoiceUrl = res.data.invoiceUrl
        this.print(res.data.invoiceUrl)
      } else {
        loading.close()
      }
    },
    // 门诊缴费凭条
    print(invoiceUrl) {
      let _this = this
      const params = {
        orderNum: this.query.orderNum,
        name: encodeURI(this.query.patName),
        patCardNo: this.query.patCardNo,
        cost: this.query.cost,
        outTradeNo: this.query.outTradeNo,
        guideListInfo: encodeURI(this.query.remark),
        invoiceUrl,
        deptName: encodeURI(this.query.deptName),
        doctorName: encodeURI(this.query.doctorName),
        date: this.query.date,
        budgeting: this.query.budgeting || 0,
        medicalInsurance: this.query.medicalInsurance || 0
      }
      Promise.all([_this.$api.printOut(params)]).then(data => {})
    },
    // 住院按金凭条
    hospitalPrint() {
      let _this = this
      const params = {
        orderNum: this.query.outTradeNo,
        name: encodeURI(this.query.patName),
        AdmissionNum: this.query.admissionNum,
        cost: this.query.cost
      }
      Promise.all([_this.$api.hospitalReceipt(params)]).then(data => {})
    },
    countdown() {
      const TIME_COUNT = 100
      this.count = TIME_COUNT
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
            console.log('succeed')
          }
        } else {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.succeed {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
p {
  font-size: 70px;
  margin-top: 5%;
  font-weight: bold;
  span {
    color: red;
  }
}
.box {
  padding: 60px;
  font-size: 80px;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: 45%;
}

.whiff {
  color: #00cc66;
  text-align: center;
}
.item_box {
  display: flex;
  justify-content: space-between;
}
</style>
